var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ReactComponent as BinanceIcon } from '@icons/exchanges/binance.svg';
import { ReactComponent as BitmexIcon } from '@icons/exchanges/bitmex.svg';
import { ReactComponent as FidelityIcon } from '@icons/exchanges/fidelity.svg';
import Icon from '@shared/ui/icons/Icon';
export const getExchangeSvgIcon = (type) => {
    if (type === 'BINANCE') {
        return BinanceIcon;
    }
    if (type === 'BITMEX') {
        return BitmexIcon;
    }
    if (type === 'FIDELITY') {
        return FidelityIcon;
    }
    return BinanceIcon; // TODO: make default icon
};
const ExchangeIcon = (_a) => {
    var { type = undefined } = _a, props = __rest(_a, ["type"]);
    const iconComponent = useMemo(() => getExchangeSvgIcon(type), [type]);
    return (_jsx(Icon
    // TODO: check it, discuss
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { IconComponent: iconComponent, keepOriginalColor: true })));
};
export default ExchangeIcon;
