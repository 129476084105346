var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import MuiCheckbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabelComponent from '@mui/material/FormControlLabel';
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as CheckboxIcon } from '@icons/custom/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from '@icons/custom/checkbox-checked.svg';
const defineDisabledProps = (theme, disabled) => (disabled ? ({
    '& span.MuiFormControlLabel-label.Mui-disabled': {
        color: theme.customColors.checkbox.textDisabled,
    },
    '&.Mui-disabled svg': {
        fill: theme.customColors.tabs.border,
    },
}) : ({}));
const FormControlLabel = styled(FormControlLabelComponent, { shouldForwardProp: propName => propName !== 'fullWidth' })(props => (Object.assign({ '.MuiFormGroup-root': {
        '&:focus': {
            svg: {
                borderRadius: 6,
                boxShadow: `0px 0px 0px 3px ${props.theme.customColors.checkbox.checkboxHover}`,
            },
        },
    }, '&.MuiFormControlLabel-root': {
        width: props.fullWidth ? '100%' : 'auto',
        margin: 0,
        '&:not(.Mui-disabled):hover': {
            svg: {
                borderRadius: 6,
                boxShadow: `0px 0px 0px 3px ${props.theme.customColors.checkbox.checkboxHover}`,
            },
        },
        '&:not(.Mui-disabled):focus': {
            svg: {
                borderRadius: 6,
                boxShadow: `0px 0px 0px 3px ${props.theme.customColors.checkbox.checkboxHover}`,
            },
        },
    }, '& .MuiTypography-root': {
        width: props.fullWidth ? '100%' : 'auto',
    }, '.MuiCheckbox-root': {
        padding: '0 12px 0 0',
    }, 'span.MuiFormControlLabel-label': {
        color: props.theme.customColors.checkbox.textDefault,
    } }, defineDisabledProps(props.theme, props.disabled))));
const CheckboxComponent = (_a, ref) => {
    var { label = '', onChange = undefined, fullWidth = false, role = undefined } = _a, props = __rest(_a, ["label", "onChange", "fullWidth", "role"]);
    return (_jsx(FormGroup, { role: role, children: _jsx(FormControlLabel, { control: (_jsx(MuiCheckbox, Object.assign({}, props, { icon: _jsx(CheckboxIcon, {}), checkedIcon: _jsx(CheckboxCheckedIcon, {}), ref: ref }))), role: 'checkbox', label: label, onChange: onChange, disabled: props.disabled, fullWidth: fullWidth }) }));
};
const Checkbox = forwardRef(CheckboxComponent);
export default Checkbox;
