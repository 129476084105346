var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { forwardRef, useMemo, } from 'react';
const IconWrapperBase = styled.section((props) => (Object.assign({ display: 'inline-flex', width: 'auto', height: 'auto' }, !props.keepOriginalColor && ({
    path: {
        fill: props.color || 'currentColor',
    },
    circle: {
        fill: props.color || 'currentColor',
    },
}))));
const IconComponent = (_a, ref) => {
    var { baseComponentType = 'div', IconComponent: Component, className = undefined, size = 18, color = undefined, keepOriginalColor = false, onClick = () => { } } = _a, props = __rest(_a, ["baseComponentType", "IconComponent", "className", "size", "color", "keepOriginalColor", "onClick"]);
    const width = typeof size === 'number' ? size : size[0];
    const height = typeof size === 'number' ? size : size[1];
    const IconWrapperComponent = useMemo(() => IconWrapperBase.withComponent(baseComponentType), [baseComponentType]);
    return (_jsx(IconWrapperComponent, Object.assign({ ref: ref, className: className, color: color, keepOriginalColor: keepOriginalColor, onClick: onClick }, props, { children: _jsx(Component, { width: width, height: height }) })));
};
const Icon = forwardRef(IconComponent);
export default Icon;
