var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ReactComponent as VisaIcon } from '@icons/payment-systems/visa.svg';
import { ReactComponent as ApplePayIcon } from '@icons/payment-systems/apple-pay.svg';
import { ReactComponent as MestroIcon } from '@icons/payment-systems/maestro.svg';
import { ReactComponent as MastercardIcon } from '@icons/payment-systems/mastercard.svg';
import { ReactComponent as PaypalIcon } from '@icons/payment-systems/paypal.svg';
import PaymentSystem from '@shared/api/payment-system';
import Icon from '@shared/ui/icons/Icon';
export const getSystemSvgIcon = (type) => {
    switch (type) {
        case PaymentSystem.Visa:
            return VisaIcon;
        case PaymentSystem.ApplePay:
            return ApplePayIcon;
        case PaymentSystem.Maestro:
            return MestroIcon;
        case PaymentSystem.MasterCard:
            return MastercardIcon;
        case PaymentSystem.PayPal:
            return PaypalIcon;
        default:
            return VisaIcon;
    }
};
const PaymentSystemIcon = (_a) => {
    var { type = undefined } = _a, props = __rest(_a, ["type"]);
    const iconComponent = useMemo(() => getSystemSvgIcon(type), [type]);
    return (_jsx(Icon
    // TODO: check it, discuss
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { IconComponent: iconComponent, keepOriginalColor: true })));
};
export default PaymentSystemIcon;
