import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { formatNumber } from '@shared/lib/numbers';
import { ReactComponent as UsersIcon } from '@icons/wolfkit-light/users-light.svg';
import Icon from '@shared/ui/icons/Icon';
import IconText from '@shared/ui/icons/IconText';
const UserPosition = styled.span((props) => ({
    color: props.theme.palette.text.primary,
}));
const FollowingIcon = ({ count, isFollowing = undefined, followerPosition = undefined, }) => {
    const totalCount = formatNumber(count, {
        compactNotation: true,
        maximumFractionDigits: 0,
    });
    return (_jsx(IconText, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: UsersIcon })), text: isFollowing ? (_jsxs(_Fragment, { children: [_jsx(UserPosition, { children: formatNumber(followerPosition, {
                        compactNotation: true,
                        maximumFractionDigits: 0,
                    }) }), ` / ${totalCount}`] })) : `${totalCount}` }));
};
export default FollowingIcon;
